/*.App {*/
/*  font-family: sans-serif;*/
/*  text-align: center;*/
/*}*/

.swagger-ui .topbar .download-url-wrapper {
  display : none;
}

.swagger-ui .info .base-url {
  display : none;
}

.swagger-ui .info hgroup.main a {
  display: none;
}

/*.swagger-ui .scheme-container {*/
/*  display : none;*/
/*}*/
